import React from "react";
import { Grid } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import logo from "../images/logo.png";

const Index = ({ data }) => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item xs={12} style={{ maxWidth: "500px" }}>
        <Img fluid={data.file.childImageSharp.fluid} />
        <h1 style={{ textAlign: "center" }}>AGUARDE!</h1>
      </Grid>
    </Grid>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Index;
